import {
  Button,
  Link,
  Typography,
  useMediaQuery,
  Box,
  Stack,
  Container,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAuth} from "src/hooks/use-auth";
import {useTheme} from "@mui/material/styles";
import React from "react";
import {paths} from "src/pages/paths";

const Home = () => {
  const auth = useAuth();
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const processSteps = [
    {
      imgSrc: "/process1.svg",
      imgAlt: "Kayıt Ol",
      title: t("mainPage.register"),
      description: t("mainPage.registerDescr"),
    },
    {
      imgSrc: "/process2.svg",
      imgAlt: "Anketleri doldur",
      title: t("mainPage.fillInSurveys"),
      description: t("mainPage.fillInSurveysDescr"),
    },
    {
      imgSrc: "/process3.svg",
      imgAlt: "Ödül Kazan",
      title: t("mainPage.winPrize"),
      description: t("mainPage.winPrizeDescr"),
    },
  ];

  const stats = [
    {
      title: t("mainPage.participantPool"),
      value: "10000+",
      description: t("mainPage.participantPoolDescr"),
    },
    {
      title: t("mainPage.surveyDiversity"),
      value: "30+",
      description: t("mainPage.surveyDiversityDescr"),
    },
    {
      title: t("mainPage.support"),
      value: "7/24",
      description: t("mainPage.supportDescr"),
    },
    {
      title: t("mainPage.rewardDiversity"),
      value: "5+",
      description: t("mainPage.rewardDiversityDescr"),
    },
  ];

  return (
    <Container
      sx={{
        minHeight: "100svh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: {xs: 2, sm: 4},
      }}
    >
      <ResponsiveImage
        src={i18n.language === "tr" ? "/name.svg" : "/name_en.svg"}
        alt="Logo"
        width={isMobile ? "85%" : isTablet ? "70%" : "57%"}
        marginTop={isMobile ? 30 : 50}
        maxHeight="150px"
      />

      <Typography
        variant="body1"
        color="textPrimary"
        textAlign="center"
        sx={{
          maxWidth: "900px",
          width: "100%",
          mt: 2,
          px: {xs: 1, sm: 2, md: 3},
          fontSize: {xs: "0.9rem", sm: "1rem"},
        }}
      >
        {t("companyDescription")}
      </Typography>

      <Stack item xs={12} alignItems="center" mt={2}>
        <Link
          href={auth.isLoggedIn() ? paths.dashboard.openSurveys : paths.auth.login}
          style={{textDecoration: "none"}}
        >
          <Button
            color="primary"
            variant="contained"
            sx={{px: {xs: 2, sm: 3}, py: {xs: 1, sm: 1.5}}}
          >
            {t("platformTour")}
          </Button>
        </Link>
      </Stack>

      <ResponsiveImage
        src="/analyticscreen.svg"
        alt="Analytics Screen"
        width={isMobile ? "95%" : isTablet ? "85%" : "70%"}
        maxHeight="300px"
        marginTop={isMobile ? 10 : 15}
      />

      {/* Process Section */}
      <Box sx={{width: "100%", mt: {xs: 6, md: 8}, mb: {xs: 4, md: 6}}}>
        <Typography
          variant="h3"
          textAlign="center"
          sx={{mb: {xs: 4, md: 6}, fontSize: {xs: "1.8rem", sm: "2.2rem", md: "2.5rem"}}}
        >
          {t("mainPage.howToUse")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            justifyContent: "space-between",
            alignItems: {xs: "center", md: "flex-start"},
            width: "100%",
          }}
        >
          {processSteps.map((step, index) => (
            <React.Fragment key={index}>
              <ProcessStep step={step} isMobile={isMobile} />
              {index < processSteps.length - 1 && <ConnectingLine />}
            </React.Fragment>
          ))}
        </Box>
      </Box>
      {/* Numbers Section */}

      {/* 
      <Box sx={{  py: 8, width: "100%" }}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            textAlign="center"
            sx={{
              color: "#primary",
              fontWeight: "bold",
              mb: 4,
              fontSize: { xs: "2rem", sm: "2.5rem" },
            }}
          >
            Galata {t("sections.survey")}   <span style={{ color: "#ad1445" }}>{t("mainPage.inNumbers")}</span>
          </Typography>
          <Grid container spacing={3}>
            {stats.map((stat, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 4,
                    backgroundColor: "#aa1545",
                    color: "#f5f5f7",
                    textAlign: "center",
                    borderRadius: "12px",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)",
                    },
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#d1d1d6", mb: 1 }}>
                    {stat.title}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: "bold", mb: 1, color: "#ffffff" }}
                  >
                    {stat.value}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#b0b0b5" }}>
                    {stat.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}
    </Container>
  );
};

const ResponsiveImage = ({src, alt, width, maxHeight, marginTop}) => (
  <img
    src={src}
    alt={alt}
    style={{
      height: "auto",
      width: width,
      maxHeight: maxHeight,
      marginTop: marginTop,
      objectFit: "contain",
    }}
  />
);

const ProcessStep = ({step, isMobile}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: {xs: "100%", md: "30%"},
      mb: {xs: 4, md: 0},
    }}
  >
    <ResponsiveImage
      src={step.imgSrc}
      alt={step.imgAlt}
      width={isMobile ? "60%" : "80%"}
      maxWidth="250px"
      marginBottom="1rem"
    />
    <Typography variant="h6" textAlign="center" sx={{mb: 1, fontWeight: "bold"}}>
      {step.title}
    </Typography>
    <Typography
      variant="body2"
      textAlign="center"
      sx={{maxWidth: "250px", color: "text.secondary"}}
    >
      {step.description}
    </Typography>
  </Box>
);

const ConnectingLine = () => (
  <Box sx={{display: {xs: "none", md: "block"}, width: "5%", alignSelf: "center"}}>
    <img src="/line.svg" alt="connecting line" style={{width: "100%", height: "auto"}} />
  </Box>
);

export default Home;
