import {Grid, Paper, Skeleton} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {projectService} from "src/services/project/project";
import {teamMemberService} from "src/services/team/team-member";
import {TeamMemberRewardsStats} from "src/entities/team/team-member";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

export const Summary = () => {
  const {t} = useTranslation();
  const [completedSurveyCount, setCompletedSurveyCount] = useState(null);
  const [totalReward, setTotalReward] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    teamMemberService.getTeamMemberStats().then(response => {
      setCompletedSurveyCount(response.completed_survey_count);
      setTotalReward(response.total_reward_amount);
    });
  }, []);

  const SummaryBoxSkeleton = () => (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        borderRadius: 2,
        textAlign: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <Skeleton variant="text" width="60%" height={32} sx={{m: "0 auto"}} />
      <Skeleton variant="text" width="40%" height={36} sx={{m: "0 auto"}} />
    </Paper>
  );

  return (
    <Grid container spacing={isMobile ? 2 : 4} sx={{mb: 4}}>
      <Grid item xs={12} sm={6}>
        {completedSurveyCount === null ? (
          <SummaryBoxSkeleton />
        ) : (
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <Typography variant="h6" color="textSecondary">
              {t("myCompletedSurvey")}
            </Typography>
            <Typography variant="h4" sx={{mt: 1}}>
              {completedSurveyCount}
            </Typography>
          </Paper>
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        {totalReward === null ? (
          <SummaryBoxSkeleton />
        ) : (
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <Typography variant="h6" color="textSecondary">
              {t("totalRewardsReceived")}
            </Typography>
            <Typography variant="h4" sx={{mt: 1}}>
              {totalReward}
            </Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};
