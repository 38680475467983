import "./App.css";
import "src/locales/i18n";

import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {AuthConsumer, AuthProvider} from "src/contexts/auth-context";
import {useRoutes} from "react-router-dom";
import {routes} from "src/routes";
import {createTheme} from "@mui/material/styles";
import {createBaseOptions} from "src/theme/base";
import {createLightOptions} from "src/theme/light";
import {CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/system";
import {SettingsConsumer, SettingsProvider} from "src/contexts/settings-context";
import GeneralAlert from "src/components/general-alert";
import {useEffect} from "react";

function App() {
  const element = useRoutes(routes);

  useEffect(() => {
    // Add meta tag to prevent translation
    const meta = document.createElement("meta");
    meta.name = "google";
    meta.content = "notranslate";
    document.getElementsByTagName("head")[0].appendChild(meta);

    // Optional: You can also add the translate="no" attribute to the html element
    document.documentElement.setAttribute("translate", "no");
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <AuthConsumer>
          {auth => (
            <SettingsProvider>
              <SettingsConsumer>
                {settings => {
                  const theme = createTheme(createBaseOptions(), createLightOptions());
                  return (
                    <ThemeProvider theme={theme}>
                      <CssBaseline />

                      <>{element}</>

                      {settings.alert.open && (
                        <GeneralAlert
                          open={settings.alert.open}
                          onClose={settings.closeAlert}
                          type={settings.alert.type}
                          message={settings.alert.message}
                        />
                      )}
                    </ThemeProvider>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
          )}
        </AuthConsumer>
      </AuthProvider>
    </LocalizationProvider>
  );
}

export default App;
