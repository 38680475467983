import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import {Box} from "@mui/system";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {ChangePasswordRequest} from "src/entities/auth";
import {authService} from "src/services/auth";
import {useSettings} from "src/hooks/use-settings";

function AccountInfoPasswordForm() {
  const {t} = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const settings = useSettings();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(t("auth.changePassword.oldPasswordRequired")),
    password: Yup.string()
      .required(t("auth.changePassword.passwordRequired"))
      .min(8, t("auth.changePassword.passwordMinLength")),
    rePassword: Yup.string()
      .required(t("auth.changePassword.reEnterPasswordRequired"))
      .oneOf([Yup.ref("password"), null], t("auth.changePassword.passwordsNotMatch")),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      rePassword: "",
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const changePasswordUpdateData = new ChangePasswordRequest(values);
        setLoading(true);
        await authService.changePassword(changePasswordUpdateData).then(() => {
          settings.showAlert("success", t("successfullyUpdated"));
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        settings.showAlert("error", err.toString() || "Failed to save ");
      }
      formik.resetForm();
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        m: 3,
        mt: 0,
      }}
    >
      {loading ? (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          <TextField
            label={t("auth.changePassword.oldPassword")}
            type={showPassword ? "text" : "password"}
            name="oldPassword"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Divider sx={{my: 2}}>
            <Typography variant="caption" color="textSecondary">
              {t("auth.changePassword.newPasswordSection")}
            </Typography>
          </Divider>

          <TextField
            label={t("auth.password")}
            type={showPassword ? "text" : "password"}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label={t("auth.reEnterPassword")}
            type={showPassword ? "text" : "password"}
            name="rePassword"
            value={formik.values.rePassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.rePassword && Boolean(formik.errors.rePassword)}
            helperText={formik.touched.rePassword && formik.errors.rePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formik.isValid || formik.isSubmitting}
          >
            {t("editInfo.changePassword")}
          </Button>
        </>
      )}
    </Box>
  );
}

export default AccountInfoPasswordForm;
