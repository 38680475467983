import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import {Box} from "@mui/system";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TeamMemberInfo} from "src/entities/team/team-member";
import {useAuth} from "src/hooks/use-auth";
import {userService} from "src/services/user";
import {useSettings} from "src/hooks/use-settings";
import {teamMemberService} from "src/services/team/team-member";
import * as Yup from "yup";

export const provinces = [
  {code: "01", name: "Adana"},
  {code: "02", name: "Adıyaman"},
  {code: "03", name: "Afyonkarahisar"},
  {code: "04", name: "Ağrı"},
  {code: "05", name: "Amasya"},
  {code: "06", name: "Ankara"},
  {code: "07", name: "Antalya"},
  {code: "08", name: "Artvin"},
  {code: "09", name: "Aydın"},
  {code: "10", name: "Balıkesir"},
  {code: "11", name: "Bilecik"},
  {code: "12", name: "Bingöl"},
  {code: "13", name: "Bitlis"},
  {code: "14", name: "Bolu"},
  {code: "15", name: "Burdur"},
  {code: "16", name: "Bursa"},
  {code: "17", name: "Çanakkale"},
  {code: "18", name: "Çankırı"},
  {code: "19", name: "Çorum"},
  {code: "20", name: "Denizli"},
  {code: "21", name: "Diyarbakır"},
  {code: "22", name: "Edirne"},
  {code: "23", name: "Elazığ"},
  {code: "24", name: "Erzincan"},
  {code: "25", name: "Erzurum"},
  {code: "26", name: "Eskişehir"},
  {code: "27", name: "Gaziantep"},
  {code: "28", name: "Giresun"},
  {code: "29", name: "Gümüşhane"},
  {code: "30", name: "Hakkari"},
  {code: "31", name: "Hatay"},
  {code: "32", name: "Isparta"},
  {code: "33", name: "Mersin"},
  {code: "34", name: "İstanbul"},
  {code: "35", name: "İzmir"},
  {code: "36", name: "Kars"},
  {code: "37", name: "Kastamonu"},
  {code: "38", name: "Kayseri"},
  {code: "39", name: "Kırklareli"},
  {code: "40", name: "Kırşehir"},
  {code: "41", name: "Kocaeli"},
  {code: "42", name: "Konya"},
  {code: "43", name: "Kütahya"},
  {code: "44", name: "Malatya"},
  {code: "45", name: "Manisa"},
  {code: "46", name: "Kahramanmaraş"},
  {code: "47", name: "Mardin"},
  {code: "48", name: "Muğla"},
  {code: "49", name: "Muş"},
  {code: "50", name: "Nevşehir"},
  {code: "51", name: "Niğde"},
  {code: "52", name: "Ordu"},
  {code: "53", name: "Rize"},
  {code: "54", name: "Sakarya"},
  {code: "55", name: "Samsun"},
  {code: "56", name: "Siirt"},
  {code: "57", name: "Sinop"},
  {code: "58", name: "Sivas"},
  {code: "59", name: "Tekirdağ"},
  {code: "60", name: "Tokat"},
  {code: "61", name: "Trabzon"},
  {code: "62", name: "Tunceli"},
  {code: "63", name: "Şanlıurfa"},
  {code: "64", name: "Uşak"},
  {code: "65", name: "Van"},
  {code: "66", name: "Yozgat"},
  {code: "67", name: "Zonguldak"},
  {code: "68", name: "Aksaray"},
  {code: "69", name: "Bayburt"},
  {code: "70", name: "Karaman"},
  {code: "71", name: "Kırıkkale"},
  {code: "72", name: "Batman"},
  {code: "73", name: "Şırnak"},
  {code: "74", name: "Bartın"},
  {code: "75", name: "Ardahan"},
  {code: "76", name: "Iğdır"},
  {code: "77", name: "Yalova"},
  {code: "78", name: "Karabük"},
  {code: "79", name: "Kilis"},
  {code: "80", name: "Osmaniye"},
  {code: "81", name: "Düzce"},
];

function AccountInfoUserForm() {
  const {t} = useTranslation();
  const auth = useAuth();
  const settings = useSettings();

  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [isTurkey, setIsTurkey] = useState(true);
  const [loadingDeleteAccountButton, setLoadingDeleteAccountButton] = useState(false);

  const userMail = auth?.user?.email;
  const userPhone = auth?.user?.phone;

  const initialValues = {
    birth_date: "",
    gender: "",
    country: "Türkiye",
    province: "",
    email: userMail || "",
    phoneNumber: userPhone || "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t("RequiredQuestionError")),
    gender: Yup.string().required(t("RequiredQuestionError")),
    birth_date: Yup.date().required(t("RequiredQuestionError")),
    country: Yup.string().required(t("RequiredQuestionError")),
    province: isTurkey && Yup.string().required(t("RequiredQuestionError")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        // Simulate save functionality
        const teamMemberUpdateData = new TeamMemberInfo(values, isTurkey);

        await teamMemberService.saveTeamMembersInfo(teamMemberUpdateData).then(() => {
          settings.showAlert("success", t("successfullyUpdated"));
        });

        setIsEditing(false);
      } catch (err) {
        settings.showAlert("error", "Failed to save ");
        console.error("Failed to save user data:", err);
      }
    },
  });

  const {values, errors, touched, handleBlur, setFieldValue, handleSubmit} = formik;

  const getTeamMemberInfo = async () => {
    try {
      const info = await teamMemberService.getTeamMembersInfo();

      const teamMemberData = new TeamMemberInfo(info);

      setFieldValue("birth_date", teamMemberData?.birth_date || "");
      setFieldValue("gender", teamMemberData?.gender || "");
      setIsTurkey(teamMemberData?.country === "Türkiye");
      setFieldValue("country", teamMemberData?.country);
      setFieldValue("province", teamMemberData?.province);
      setFieldValue("email", teamMemberData?.email);
      setLoading(false);
    } catch (err) {
      console.error("Failed to fetch team member info:", err);
    }
  };

  useEffect(() => {
    getTeamMemberInfo();
  }, []);

  const handleOpenDeleteDialog = () => setOpen(true);
  const handleCloseDeleteDialog = () => setOpen(false);

  const handleDeleteAccount = () => {
    setLoadingDeleteAccountButton(true);
    userService.deactivateUser().then(_ => {
      auth.signOut().then(_ => {
        setLoadingDeleteAccountButton(false);
      });
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{display: "flex", flexDirection: "column", gap: 2, m: 3}}
    >
      {loading ? (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {/* Email and Phone Number */}
          <TextField
            sx={{width: "100%"}}
            label={t("editInfo.phoneNumber")}
            value={values.phoneNumber}
            disabled
          />

          <TextField
            label={t("editInfo.mail")}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={!isEditing}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          {/* Gender and Birthday */}
          <TextField
            label={t("editInfo.gender")}
            select
            name="gender"
            value={values.gender}
            onChange={formik.handleChange}
            onBlur={handleBlur}
            disabled={!isEditing}
            InputLabelProps={{shrink: true}}
            error={touched.gender && Boolean(errors.gender)}
            helperText={touched.gender && errors.gender}
          >
            {[
              {key: "male", value: "Male"},
              {key: "female", value: "Female"},
              {key: "other", value: "Other"},
            ].map(gender => (
              <MenuItem key={gender.value} value={gender.value}>
                {t(gender.key)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label={t("editInfo.birthday")}
            type="date"
            name="birth_date"
            InputLabelProps={{shrink: true}} // Ensures the label is shrunk for date inputs
            value={values.birth_date}
            onChange={formik.handleChange}
            onBlur={handleBlur}
            disabled={!isEditing}
            error={touched.birth_date && Boolean(errors.birth_date)}
            helperText={touched.birth_date && errors.birth_date}
          />

          {/* Country and Province */}
          <FormControl sx={{width: "100%"}}>
            <TextField
              label={t("editInfo.country")}
              select
              name="gender"
              value={isTurkey}
              onChange={event => {
                const {value} = event.target;
                setIsTurkey(value);
                formik.setFieldValue("country", value ? "Türkiye" : "");
                formik.setFieldValue("province", "");
              }}
              onBlur={handleBlur}
              disabled={!isEditing}
              InputLabelProps={{shrink: true}}
              error={touched.gender && Boolean(errors.gender)}
              helperText={touched.gender && errors.gender}
            >
              <MenuItem value={true}>{t("Türkiye")}</MenuItem>
              <MenuItem value={false}>{t("Diğer")}</MenuItem>
            </TextField>
          </FormControl>

          {isTurkey ? (
            <TextField
              disabled={!isEditing}
              error={touched.province && Boolean(errors.province)}
              helperText={touched.province && errors.province}
              label={t("editInfo.province")}
              name="province"
              onBlur={handleBlur}
              onChange={formik.handleChange}
              value={values.province}
              select
            >
              {provinces.map(province => (
                <MenuItem key={province.code} value={province.name}>
                  {province.code} - {province.name}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              disabled={!isEditing}
              sx={{width: "100%"}}
              label={t("editInfo.country2")}
              name="country"
              value={values.country}
              onChange={formik.handleChange}
              onBlur={handleBlur}
              error={touched.country && Boolean(errors.country)}
              helperText={touched.country && errors.country}
            />
          )}

          {/* Actions */}
          <Button
            variant="contained"
            sx={{width: "100%"}}
            onClick={isEditing ? handleSubmit : () => setIsEditing(!isEditing)}
          >
            {isEditing
              ? t("editInfo.saveAccountInformation")
              : t("editInfo.editAccountInformation")}
          </Button>

          <Button
            variant="outlined"
            color="error"
            sx={{width: "100%"}}
            onClick={handleOpenDeleteDialog}
          >
            {t("editInfo.deleteAccount")}
          </Button>

          {/* Delete Dialog */}
          <Dialog open={open} onClose={handleCloseDeleteDialog}>
            <DialogTitle>{t("editInfo.deleteAccountConfirmation")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t("editInfo.deleteAccountConfirmationDescription")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">
                {t("editInfo.cancel")}
              </Button>
              <Button onClick={handleDeleteAccount} color="error" autoFocus>
                {loadingDeleteAccountButton ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("editInfo.delete")
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default AccountInfoUserForm;
