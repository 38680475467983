import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSettings} from "src/hooks/use-settings";
import {teamMemberService} from "src/services/team/team-member";

const CancelRewardButtonAndDialog = ({
  id,
  setRewardRequests,
  disabled,
  getTeamMemberRewardsStats,
}) => {
  const {t} = useTranslation();
  const settings = useSettings();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const cancelRewardRequest = id => {
    setLoading(true);

    teamMemberService
      .cancelTeamMemberRewardRequest(id)
      .then(response => {
        setRewardRequests(prevRequests =>
          prevRequests.map(request => (request.id === id ? response : request)),
        );
        getTeamMemberRewardsStats();
        settings.showAlert("success", t("rewards.rewardCancelledSuccess"));
        setOpen(false);
      })
      .catch(error => {
        settings.showAlert("error", t("rewards.rewardCancelledError"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button disabled={disabled} onClick={handleOpenDialog}>
        {t("rewards.cancelRequest")}
      </Button>

      {/* Cancel Reward Dialog */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{t("rewards.rewardCancelConfirmation")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("rewards.rewardCancelConfirmationDescription")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t("rewards.cancel")}
          </Button>
          <Button
            onClick={() => cancelRewardRequest(id)}
            color="error"
            autoFocus
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("rewards.confirmCancel")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CancelRewardButtonAndDialog;
