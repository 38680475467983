import {useState} from "react";
import {
  Container,
  Typography,
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";
import {
  ExpandMore,
  AccountCircle,
  Computer,
  Campaign,
  Payment,
  Poll,
  CardGiftcard,
  Store,
  AccessTime,
  People,
  PhoneAndroid,
  ArrowForward,
} from "@mui/icons-material";
import {paths} from "src/pages/paths";
import {useTranslation} from "react-i18next";

const getFaqCategories = t => ({
  account: [
    {
      question: t("faq.multipleAccount"),
      answer: t("faq.answMultipleAccount"),
      icon: AccountCircle,
    },
    {
      question: t("faq.changePassword"),
      answer: t("faq.answChangePassword"),
      icon: Computer,
    },
    {
      question: t("faq.notificationPreferences"),
      answer: t("faq.answNotificationPreferences"),
      icon: Campaign,
    },
    {
      question: t("faq.paymentInformation"),
      answer: t("faq.answPaymentInformation"),
      icon: Payment,
    },
  ],
  surveys: [
    {
      question: t("faq.whoCanParticipate"),
      answer: t("faq.answWhoCanParticipate"),
      icon: People,
    },
    {
      question: t("faq.howCanIBeInformed"),
      answer: t("faq.answHowCanIBeInformed"),
      icon: Poll,
    },
    {
      question: t("faq.canIFillMobileDevice"),
      answer: t("faq.answCanIFillMobileDevice"),
      icon: PhoneAndroid,
    },
  ],
  giftCodes: [
    {
      question: t("faq.howToUseGiftCodes"),
      answer: t("faq.answHowCanIUseGiftCodes"),
      icon: CardGiftcard,
      link: paths.howtousegiftcodes,
    },
    {
      question: t("faq.validityPeriod"),
      answer: t("faq.answValidityPeriod"),
      icon: AccessTime,
    },
    {
      question: t("faq.lostGiftCode"),
      answer: t("faq.answLostGiftCode"),
      icon: Store,
    },
  ],
});

export const GeneralFAQ = () => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const faqCategories = getFaqCategories(t);

  const categories = [
    {label: t("faq.accountOperations"), value: "account", icon: AccountCircle},
    {label: t("faq.surveys"), value: "surveys", icon: Poll},
    {label: t("faq.giftCodes"), value: "giftCodes", icon: CardGiftcard},
  ];

  return (
    <Container maxWidth="lg" sx={{py: 6}}>
      {/* Header Section */}
      <Box textAlign="center" mb={6}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          fontWeight="bold"
          color="primary"
          sx={{
            fontSize: {xs: "2rem", md: "3rem"},
            mb: 3,
          }}
        >
          {t("faq.faqTitle")}
        </Typography>

        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{maxWidth: "800px", mx: "auto", mb: 4}}
        >
          {t("faq.faqDescr")}
        </Typography>
      </Box>

      {/* Category Tabs */}
      <Paper
        elevation={0}
        sx={{
          maxWidth: "800px",
          mx: "auto",
          mb: 4,
          borderRadius: 3,
          border: `1px solid ${theme.palette.divider}`,
          overflow: "hidden",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{borderBottom: `1px solid ${theme.palette.divider}`}}
        >
          {categories.map((category, index) => (
            <Tab
              key={category.value}
              icon={<category.icon />}
              label={category.label}
              sx={{
                minHeight: 72,
                textTransform: "none",
                fontSize: "1rem",
                fontWeight: "medium",
              }}
            />
          ))}
        </Tabs>

        {/* FAQ Accordions */}
        <Box sx={{p: 3}}>
          {faqCategories[categories[selectedTab].value].map((faq, index) => {
            const Icon = faq.icon;
            return (
              <Accordion
                key={index}
                sx={{
                  mb: 2,
                  borderRadius: "8px !important",
                  "&:before": {display: "none"},
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.divider}`,
                  "&:first-of-type": {borderRadius: "8px"},
                  "&:last-of-type": {borderRadius: "8px"},
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    "&:hover": {backgroundColor: "rgba(0, 0, 0, 0.02)"},
                  }}
                >
                  <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                    <Icon color="primary" />
                    <Typography variant="subtitle1" fontWeight="500">
                      {faq.question}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{mb: faq.link ? 2 : 0}}
                  >
                    {faq.answer}
                  </Typography>
                  {faq.link && (
                    <Link
                      href={faq.link}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        color: "primary.main",
                        textDecoration: "none",
                        "&:hover": {textDecoration: "underline"},
                      }}
                    >
                      {t("detailedInformation")}
                      <ArrowForward fontSize="small" />
                    </Link>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Paper>
    </Container>
  );
};

export default GeneralFAQ;
