import * as Yup from "yup";
import "dayjs/locale/tr";
import {useFormik} from "formik";
import {useRouter} from "src/hooks/use-router";
import {Stack} from "@mui/system";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams, useSearchParams} from "react-router-dom";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import {authService} from "src/services/auth";
import {paths} from "src/pages/paths";
import {format} from "date-fns";
import Box from "@mui/material/Box";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {TeamMemberInfo} from "src/entities/team/team-member";
import {provinces} from "../dashboard/accountinfo/account-info-user-form";
import {useTranslation} from "react-i18next";
import {useSettings} from "src/hooks/use-settings";

const initialValues = {
  birthDate: null,
  gender: null,
  notifications: true,
  password: "",
  showPassword: false,
  submit: null,
  country: "Türkiye",
  province: "",
};

export const UserActivation = () => {
  const {t} = useTranslation();
  const router = useRouter();
  const {activationToken} = useParams();
  const settings = useSettings();
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [isTurkey, setIsTurkey] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const validationSchema = Yup.object({
    birthDate: Yup.date().required("Bu kısmın doldurulması gereklidir."),
    gender: Yup.string().max(255).required("Bu kısmın doldurulması gereklidir."),
    notifications: Yup.bool().notRequired(),
    password: Yup.string().max(255).required("Bu kısmın doldurulması gereklidir."),
    country: Yup.string().required("Bu kısmın doldurulması gereklidir."),
    province: isTurkey && Yup.string().required("Bu kısmın doldurulması gereklidir."),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => {
      try {
        setLoading(true);

        const teamMemberData = new TeamMemberInfo(values, isTurkey);

        const uniqueParameters = {};
        for (const [name, value] of searchParams.entries()) {
          uniqueParameters[name] = value;
        }
        const payload = {
          birth_date: `${format(values.birthDate.$d, "yyyy-MM-dd")}`,
          gender: values.gender,
          notifications: values.notifications,
          password: values.password,
          token: activationToken,
          country: teamMemberData.country,
          province: teamMemberData.province,
          session_key: uniqueParameters.session_key,
        };
        authService
          .activateUser(payload)
          .then(response => {
            router.push(paths.auth.login);
            setLoading(false);
            helpers.setSubmitting(false);
          })
          .catch(() => {
            setLoading(false);
            helpers.setSubmitting(false);
            settings.showAlert("error", t("somethingWentWrong"));
          });
      } catch (err) {
        setLoading(false);
        helpers.setSubmitting(false);
        console.error(err);
      }
    },
  });

  const testFunction = val => {
    if (formik.values.birthDate === null) {
      formik.setErrors({birthDate: "Bu kısmın doldurulması gereklidir."});
    }
  };

  return (
    <>
      <Stack sx={{mb: 4}} spacing={1}>
        <Typography variant="h5">Aktivasyon</Typography>
      </Stack>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"tr"}>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <DatePicker
              label={"Doğum Tarihi"}
              onChange={value => formik.setFieldValue("birthDate", value, true)}
              disableFuture={true}
              onSelectedSectionsChange={testFunction}
              slotProps={{
                textField: {
                  error: Boolean(formik.errors.birthDate),
                  helperText: Boolean(formik.errors.birthDate)
                    ? "Bu kısmın doldurulması gereklidir."
                    : "",
                },
              }}
            />
            <TextField
              error={!!(formik.touched.gender && formik.errors.gender)}
              fullWidth
              helperText={formik.touched.gender && formik.errors.gender}
              label="Cinsiyet"
              name="gender"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.gender}
              select
            >
              {[
                {key: "male", value: "Male"},
                {key: "female", value: "Female"},
                {key: "other", value: "Other"},
              ].map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {t(item.key)}
                </MenuItem>
              ))}
            </TextField>
            {/* Country and Province */}
            <FormControl sx={{width: "100%"}}>
              <InputLabel>{"Hangi ülkede yaşıyorsun?"}</InputLabel>
              <Select
                value={isTurkey}
                onChange={event => {
                  const {value} = event.target;
                  setIsTurkey(value);
                  formik.setFieldValue("country", value ? "Türkiye" : "");
                  formik.setFieldValue("province", "");
                }}
                label={t("editInfo.country")}
              >
                <MenuItem value={true}>{t("Türkiye")}</MenuItem>
                <MenuItem value={false}>{t("Diğer")}</MenuItem>
              </Select>
            </FormControl>

            {isTurkey ? (
              <TextField
                error={!!(formik.touched.province && formik.errors.province)}
                helperText={formik.touched.province && formik.errors.province}
                label={"Hangi şehir?"}
                name="province"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.province}
                select
              >
                {provinces.map(province => (
                  <MenuItem key={province.code} value={province.name}>
                    {province.code} - {province.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                sx={{width: "100%"}}
                label={"Hangi ülke?"}
                variant="outlined"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            )}

            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Şifre"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              type={formik.values.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("showPassword", !formik.values.showPassword)
                      }
                      edge="end"
                    >
                      {formik.values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box sx={{mt: 3}}>
            Sizleri anket sistemimize dahil edip anketler ve/ya ödüller için haberdar
            edebilmemiz için aşağıdaki metinleri onaylamanız gerekmektedir.
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacy}
                  onChange={_ => setPrivacy(!privacy)}
                  name="agreement"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  <Link
                    href="/documents/register-page/Galata Anket - Katılımcı Açık Rıza Metni.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Katılımcı Açık Rıza Metni
                  </Link>
                  ,{" "}
                  <Link
                    href="/documents/register-page/Galata Anket - Katılımcı Aydınlatma Metni.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Katılımcı Aydınlatma Metni
                  </Link>{" "}
                  ve{" "}
                  <Link
                    href="/documents/register-page/Galata Anket - Katılımcı Anket İçeriği Aydınlatma Metni.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Anket İçeriği Aydınlatma Metnini
                  </Link>{" "}
                  okudum ve kabul ediyorum.
                </Typography>
              }
            />
          </Box>
          <Box sx={{mt: 0}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={terms}
                  onChange={_ => setTerms(!terms)}
                  name="agreement"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  <Link
                    href="/documents/register-page/Galata Anket - Katılımcı Anket İçeriği Aydınlatma Metni.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ticari Elektronik İleti Onay Metnini
                  </Link>{" "}
                  okudum ve kabul ediyorum.
                </Typography>
              }
            />
          </Box>
          <Button
            fullWidth
            disabled={terms && privacy ? formik.isSubmitting : true}
            sx={{mt: 3}}
            size="large"
            type="submit"
            variant="contained"
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Kayıt ol"}
          </Button>
        </form>
      </LocalizationProvider>
    </>
  );
};
