export const paths = {
  index: "/",
  about: "about",
  contact: "contact",
  giftcodesinstructions: "giftcodesinstructions",
  sss: "/sss",
  howtousegiftcodes: "/howtousegiftcodes",
  auth: {
    login: "/auth/login",
    register: "/auth/register",
  },
  survey: "/survey/:id",
  dashboard: {
    openSurveys: "/dashboard/open-surveys",
    completedSurveys: "/dashboard/completed-surveys",
    rewards: "/dashboard/rewards",
    accountInfo: "/dashboard/accountinfo",
    sss: "/dashboard/sss",
  },
};
