import {lazy, Suspense} from "react";
import {Outlet} from "react-router-dom";
import Home from "src/pages/main/home";
import About from "src/pages/main/about";
import Contact from "src/pages/main/contact/index";
import GiftCodesInstructions from "src/pages/dashboard/gift-code-instructions";
import GeneralFAQ from "src/pages/dashboard/general-faq";

const MainLayout = lazy(() => import("src/layouts/main/main"));
//const OpenSurveys = lazy(() => import("src/pages/dashboard/open-surveys"));
//const CompletedSurveys = lazy(() => import("src/pages/dashboard/completed-surveys"));
//const Rewards = lazy(() => import("src/pages/dashboard/rewards"));

export const mainRoutes = [
  {
    path: "",
    element: (
      <MainLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "giftcodesinstructions",
        element: <GiftCodesInstructions />,
      },
      {
        path: "sss",
        element: <GeneralFAQ />,
      },
      {
        path: "howtousegiftcodes",
        element: <GiftCodesInstructions />,
      },
    ],
  },
];
