import Box from "@mui/material/Box";
import {Button, CardContent, Tooltip, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import {useSurveyEngine} from "src/hooks/use-survey-engine";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import Link03Icon from "@untitled-ui/icons-react/build/esm/Link03";
import {paths} from "src/pages/paths";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSettings} from "src/hooks/use-settings";

export default function EndPage() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const surveyEngine = useSurveyEngine();
  const [surveyParams, setSurveyParams] = useSearchParams();
  const {uuid} = useParams();
  const settings = useSettings();

  const handleCopySurveyUrl = () => {
    const parameterArray = ["share=true"];
    for (let entry of surveyParams.entries()) {
      if (entry[0] !== "share") {
        parameterArray.push(`${entry[0]}=${entry[1]}`);
      }
    }
    const parameters = parameterArray.join("&");
    navigator.clipboard
      .writeText(`${window.location.origin}${paths.survey}/${uuid}?${parameters}`)
      .then(_ => settings.showAlert("success", t("surveyUrlCopied"))) // FIXME: use locales
      .catch(_ => settings.showAlert("error", t("surveyUrlNotCopied")));
  };

  return (
    <Box sx={{px: "5%"}}>
      <Card>
        <CardContent>
          {surveyEngine.survey.end_page_message ? (
            <Typography
              dangerouslySetInnerHTML={{
                __html: surveyEngine.survey.end_page_message,
              }}
            />
          ) : (
            <Typography
              variant="body1"
              textAlign="center"
              sx={{
                py: 2, // Dikey padding
                fontSize: {xs: "1rem", sm: "1.5rem", md: "2rem"}, // Ekran boyutuna göre yazı boyutu
              }}
            >
              {t("defaultEndPageMessage")}
            </Typography>
          )}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            padding={4} // Tüm kenarlara eşit padding
            gap={2} // Elemanlar arasında boşluk
          >
            {surveyEngine.submission.isParticipant ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/dashboard/open-surveys")}
                  sx={{width: "100%", maxWidth: "300px"}} // Buton genişliğini sınırlamak
                >
                  {t("goToMySurveys")} {/* Çeviri için: "Anketlerime Dön" */}
                </Button>
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  textAlign="center"
                  sx={{
                    mb: 1,
                    fontSize: {xs: "0.9rem", sm: "1rem", md: "1.1rem"}, // Ekran boyutuna göre yazı boyutu
                  }}
                >
                  {t("registerToEarnRewards")}{" "}
                  {/* Çeviri için: "Ödülleri almak için kaydolabilirsin" */}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/auth/register")}
                  sx={{width: "100%", maxWidth: "300px"}} // Buton genişliği
                >
                  {t("registerNow")} {/* Çeviri için: "Şimdi Kaydol" */}
                </Button>
              </>
            )}
          </Box>
          {surveyEngine.survey?.share_link && (
            <Box display="center" justifyContent="center">
              <Tooltip title={t("copySurveyLink")} placement="left" arrow>
                <IconButton onClick={handleCopySurveyUrl} color={"primary"}>
                  <SvgIcon>
                    <Link03Icon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
